<template>
    <div class="faq">
        <Header />
        <div class="banner">
            <div class="container">
                <div class="title">Blogs</div>
            </div>
        </div>
        <div class="main">
            <div class="container">
                <div class="warpper">
                    <div class="ddRow" @click="goPage(1)">
                        <div class="ddCol-6">
                            <img class="blogs-img" src="~@/assets/images/blogs1.png">
                        </div>
                        <div class="ddCol-6 w100">
                            Long gone are the days when lengthy documentation and paperwork was vital for selling
                            out your vehicle. With the advancement of technology and digitalisation, the automotive
                            business has hit a new high. The global world is making huge profits by selling their
                            cars online in the snap of fingers. Here are the top 4 tips if you want to become one of
                            them.
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="ddRow fan" @click="goPage(2)">
                        <div class="ddCol-6 w100">
                            Car finance entirely depends upon your financial situation and car specific budget. Multiple
                            income sources and a good balance of expenses provides you with the opportunity to spend big on
                            car financing. Online automotive websites are reporting that people are paying great heed to
                            online purchases of the car, which is good news for car financing as well. We can start the
                            financing strategy by considering the following aspects of car financing.
                        </div>
                        <div class="ddCol-6">
                            <img class="blogs-img" src="~@/assets/images/blogs2.png">
                        </div>

                    </div>
                    <div class="line"></div>
                    <div class="ddRow" @click="goPage(3)">
                        <div class="ddCol-6">
                            <img class="blogs-img" src="~@/assets/images/blogs3.png">
                        </div>
                        <div class="ddCol-6 w100">
                            Selling your car at the desired worth is not everyone's strong suit. Multiple factors play their
                            role in the successful dealing of the automotive business. Here are the top five points that one
                            needs to consider while selling their car.
                        </div>


                    </div>
                    <div class="line"></div>
                    <div class="ddRow fan" @click="goPage(4)">
                        <div class="ddCol-6 w100">
                            Looking after your belongings and maintaining the products you buy is an art closely valued by
                            many. Turning a blind eye towards your household stuff is considered casual and depreciated by
                            people around. A person, no matter filthy rich or belonging to a middle class, if he values his
                            accessories and takes good care of his house, family, property and car, reflects a great
                            character and responsibility. <br /><br />
                            A gearhead will genuinely understand the importance of maintaining a vehicle. Here are the top 5
                            tips by the experts of car evaluation for improving the market value of your car.
                        </div>
                        <div class="ddCol-6">
                            <img class="blogs-img" src="~@/assets/images/blogs4.png">
                        </div>

                    </div>
                    <div class="line"></div>

                    <div class="ddRow" @click="goPage(5)">
                        <div class="ddCol-6">
                            <img class="blogs-img" src="~@/assets/images/blogs5.png">
                        </div>
                        <div class="ddCol-6 w100">
                            Buying a used car or any other vehicle can be a tricky task. The automotive business is filled
                            with scammers and cons. To avoid such a devastating experience, one needs to have a support of a
                            credible authority that can assure safe passage for dealing and purchasing a used car. It is
                            effortless for someone new in the automotive dealing business to make a fool out of him and rip
                            him off heavily. Common people unknowingly are dragged into financial fraud, and it takes years
                            to come out of the financial debt they had to bear for someone else's sin.
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,

    },
    data() {
        return {};
    },
    methods: {
        goPage(type) {
            if (type == 1) {
                this.$router.push({
                    name: "Kong",
                    query: { path: "/how-to-sell-your-car-online", index: 5 },
                });
            }
            if (type == 2) {
                this.$router.push({
                    name: "Kong",
                    query: { path: "/how-to-finance-a-car", index: 5 },
                });
            }
            if (type == 3) {
                this.$router.push({
                    name: "Kong",
                    query: { path: "/how-to-sell-your-car-a-complete-guide", index: 5 },
                });
            }
            if (type == 4) {
                this.$router.push({
                    name: "Kong",
                    query: { path: "/how-to-improve-the-market-value-of-your-car", index: 5 },
                });
            }
            if (type == 5) {
                this.$router.push({
                    name: "Kong",
                    query: { path: "/ways-to-check-if-a-car-has-finance", index: 5 },
                });
            }
        }
    }
};
</script>
  
<style scoped lang="less">
.ddRow {
    font-family: Ubuntu-Regular, Ubuntu;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 1% 0;
    width: 100%;
    cursor: pointer;
}

.ddRow h2,
.ddRow h3 {
    font-weight: bold;
    color: #004AAD;
    margin: 4% 0 2% 0;
    text-align: center;
}

.ddRow ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 2%;
    margin-left: 4%;
}

.ddRow img {
    width: 100%;
}

.ddCol-12 {
    width: 100%;
}

.ddCol-6 {
    width: 50%;
    padding: 2%;
    float: left;
}

.ddRow:after {
    content: "";
    display: table;
    clear: both;
}

.w100 {
    width: 100%;
}

.line {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.23999999463558197);
    margin: 24px 0 24px 0;
}

@media only screen and (max-device-width: 960px) {
    .ddRow {
        display: flex;
        flex-direction: column;
               
    }
    .fan{
        flex-direction: column-reverse;
    }

    .ddCol-6 {
        width: 100%;
    }

    .w100 {
        font-size: 12px;
    }
}

.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }

    .blogs-img {
        width: 300px;
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 132px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}
</style>
  